<template>
  <section class="site-index">
    <div class="content first-container content-splash">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <lottie
              :options="defaultOptions"
              :height="'auto'"
              :width="'100%'"
              v-on:animCreated="handleAnimation"
            />
          </div>

          <div class="col-lg-6">
            <div class="txt-container">
              <span class="me">Hi! My name is <span class="kc">KC</span>, I'm a</span>
              <div class="stage">
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
                <div class="layer"></div>
              </div>
              <div class="platform-container">
                <span class="platforms">at <a rel="noopener" target="_blank" href="https://eightfour.sg/"><span class="blueTxt">Eightfour Digital</span></a>. with an interest for animations and design techniques. I do mobile app and web development in the workforce and used Unity 3D occasionally.</span>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="scrolldown"><span>&#8592; Scroll down</span></div>
      <span class="nickname">x3iidestiny</span>
    </div>

    <div class="content content-intro">
      <div class="typed-container">
        <span class="typed"></span>
      </div>
      <div class="container-fluid">
        <div class="row">
            
          <div class="col-lg-6">
            <span class="head myself">SKILLS <span class="small">スキル</span></span>
          </div>
        </div>

      </div>
       
      <div class="whatIdo">
      
        <div class="container-fluid">
          <div class="row align-items-center">
            <span class="body col-lg-6">From hands-on experience on Game development and Photoshop editing in my Poly days to Website, Mobile development and many more in the workforce.<br><br>I wish to learn new modern technologies and the area of an UIUX developer before becoming a fullstack developer.</span>
            <div class="skills col-lg-6">
              <div class="skillbar"><span class="nine">HTML & CSS</span></div>
              <div class="skillbar"><span class="nine">Javascript</span></div>
              <div class="skillbar"><span class="eight">React Native</span></div>
              <div class="skillbar"><span class="six">Vue</span></div>
              <div class="skillbar"><span class="four">React</span></div>
              <div class="skillbar"><span class="four">Node.js</span></div>
              <div class="skillbar"><span class="five">Unity 3D</span></div>
              <div class="skillbar"><span class="four">Photoshop</span></div>
            </div>
          </div>
        </div>

      </div>

    </div>

    <div class="bg bg-meteor"></div>

    <div class="content content-portfolio">
      
      <div class="projects-group" animate-qualities>
        <div class="inner-container">
          <div class="container-fluid">
            <div class="row text-md-center">
              <div class="col-12">
                <span class="title">PROJECTS <span class="small">プロジェクト</span></span>
              </div>
            </div>
          </div>
          
          <div class="project-slides" animate-pin animate-fade animate-me>

            <div class="container-fluid">
              <div class="projects" animate-item>
                <div class="project" v-for="item in projectsData" :key="item.id">
                  <router-link class="project-card" :to="item.path">
                    <img :src="item.thumbnail" :alt="item.title">
                    <span class="project-name">{{item.title}}</span>
                  </router-link>
                </div>
              </div>
            </div>

          </div>
          <router-link animate-me class="viewAll" to="/Projects">View all projects</router-link>
        </div>
      </div>


    </div>

    <div class="bg bg-sketch">
      <div class="container-fluid">
        <div class="row align-items-center h-100">
          <div animate-me class="col-md-6 ml-auto text-container text-md-right">
            <span>Other than official projects,<br>wonder what other things I've done?</span>
            <router-link class="letsGo" to="/Research" animate-me>Take a look</router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="content content-interest">
      <div class="textBg"/>

      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 ml-auto text-lg-right text-left">
            <span class="head">INTERESTS <span class="small">趣味</span></span>
          </div>
        </div>

      </div>

      <div class="myInterest">

        <div class="container-fluid">
          <div class="row align-items-center">

            <div class="col-lg-6">
              <div class="imgBg"/>
            </div>

            <div class="col-lg-6">
              <div class="texts">
                <span>I enjoy a little Game developing (and playing them too), Photoshop editing and Pencil drawing and playing the bass in my free time.<br><br>I am a big fan of Japanese language and culture eg. Japanese animation, Japanese music and of course their food.</span>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

  </section>
</template>

<script>
import { ref, onMounted, onBeforeMount, onBeforeUnmount, reactive, computed } from 'vue';
import Lottie from "vue-lottie";
import Func from '@/helpers/Function';
import * as animationData from '@/assets/work_anim.json';
import { useStore } from 'vuex';
import Data from '@/helpers/Data';

export default {
  setup(props, {emit}) {
    const defaultOptions = {animationData: animationData.default, renderer: 'svg', rendererSettings: { progressiveLoad:false, preserveAspectRatio: 'xMaxYMax slice' },};
    const animationSpeed = 1;
    const store = useStore();
    const userMode = computed(() => store.state.account.mode);
    var controller;
    const projectsData = ref({});

    onBeforeMount(()=>{
      let projArr = Object.fromEntries(
        Object.entries(Data).slice(0, 6)
      );
      projectsData.value = projArr;

      // if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      //   alert('dark mode');
      // } else {
      //   alert('light mode');
      // }
    });

    onMounted(() => {
      // console.log('mounted home page');
      Func.resetPage();
      Func.setupScrolltop();
      if(userMode.value == 'dark') {
        $(".site-index").addClass('darkmode');
        $(".scrolldown-container").addClass('darkmode');
      } else {
        $(".site-index").removeClass('darkmode');
        $(".scrolldown-container").removeClass('darkmode');
      }
      var typed = new Typed('.typed', {
        strings: ['code', 'relax', 'music', 'draw'],
        typeSpeed: 50,
        backSpeed: 50,
        loop: true
      });
      //
      $('[animate-me]').each(function () {
        const c = new window.ScrollMagic.Controller();
        const t = gsap.from($(this), 1, { autoAlpha: 0, y: 50 });

        new window.ScrollMagic.Scene({
          triggerElement: this,
          offset: 0,
          reverse: false
        })
        .setTween(t)
        .addTo(c);
      });
      //
      setupScrollMagic();
      setupHorizontalScroll();
      // check if inited before calling, because now transiting to project page and back is causing warnings and errors!
      $(window).on("scroll", function(){
        let originalScale = 150;
        let windowPos = $(window).scrollTop();
        let yPosToHitMeteor = $('.bg-meteor').offset().top - 200;
        if(windowPos < yPosToHitMeteor) {
          let newScale = originalScale - (windowPos/yPosToHitMeteor)*50
           $('.bg-meteor').css('background-size', newScale + '%');
        }

        let yPosToHitSketch = $('.bg-sketch').offset().top - 200;
        if(windowPos < yPosToHitSketch) {
          let newScale = originalScale - (windowPos/yPosToHitSketch)*50
           $('.bg-sketch').css('background-size', newScale + '%');
        }
      });
    });

    function setupScrollMagic() {
      controller = new ScrollMagic.Controller();
      var sections = [".content-intro"];
      sections.forEach(function (section, index) {     
        var tm = new TimelineMax();
				tm.add(TweenMax.from(".content-intro .container-fluid", 0.5, {x:"10%", autoAlpha:0}), 0);
        var scene = new ScrollMagic.Scene({ 
          triggerElement: section, 
          reverse: false
        })
        .setTween(tm)
        .addTo(controller);
        scene.on("start", function (event) {
          $(".content-intro .myself").addClass('active');
          setTimeout(() => {
            $(".skills .skillbar span").addClass('active');
          }, 250);
          var target = event.target.triggerElement();
          var key = $(target).attr('class');
				});
      });

      var tm2 = new TimelineMax();
      var scene2 = new ScrollMagic.Scene({ 
        triggerElement: ".content-portfolio", 
        reverse: false
      })
      .setTween(tm2)
      .addTo(controller);
      scene2.on("start", function (event) {
        $(".content-portfolio .inner-container .title").addClass('active');
      });

      var tm3 = new TimelineMax();
      tm3.add(TweenMax.from(".content-interest .container-fluid", 0.5, {x:"-10%", autoAlpha:0}), 0);
      var scene3 = new ScrollMagic.Scene({ 
        triggerElement: ".content-interest", 
        reverse: false
      })
      .setTween(tm3)
      .addTo(controller);
      scene3.on("start", function (event) {
        $(".content-interest .head").addClass('active');
      });
    }

    function setupHorizontalScroll() {
      
      $('[animate-qualities]').each(function () {

        let c = null;
        let s = null;
        let tween = null;

        const animate = () => {
          // console.log(this.projectsData);
          gsap.defaults({overwrite: false});
          c = new window.ScrollMagic.Controller();
          tween = new gsap.timeline();
          tween.to($(this).find('[animate-item]'), 1, { x: '-300%' });

          s = new window.ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 'onLeave',
            duration: '300%'
          })
          .setPin(this)
          .setTween(tween)
          .addTo(c);
        };

        if ($(window).width() > 991) {
          animate();
        }

        $(window).resize(function(){
          if ($(window).width() > 991) {
            if (!c) {
              animate();
            }
          } else if ($(window).width() <= 991) {
            if (c) {
              tween.kill();
              s = s.destroy(true);
              c = c.destroy(true);
            }
          }
        });

      });
    }

    function handleAnimation (anim) {
    }

    function stop (anim) {
      anim.stop();
    }
    
    onBeforeUnmount(()=>{
      $(window).off("scroll");
    });

    return {
      Lottie,
      defaultOptions,
      animationSpeed,
      handleAnimation,
      stop,
      projectsData
    }
  }
}
</script>

<style scoped>
</style>
